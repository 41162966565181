
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    
.btn {

    &-default {
        box-shadow: 0 0 10px 0 #00000040;
        background: none;
        color: $white;
        padding: 1rem 2rem;
        text-decoration: none;
        border-radius: 12px;
        display: flex;
        align-items: center;
        transition: .25s ease-in-out all;
        font-weight: 600;
        font-size: 0.875rem;
        border: 2px solid $blue;

        &:hover, &.isActive {
            background: $gradientDefault;
            border: 2px solid $blue!important;
            color: $white!important;
        }
        @media screen and (max-width: $md) {
            padding: 1rem 2rem;
            text-align: left;
            white-space: nowrap;
            font-size: 0.875rem;
        }
    }
    &-arrow {
        box-shadow: 0 0 10px 0 #00000040;
        background: $gradientButton;
        color: $white;
        padding: 1rem 3rem;
        text-decoration: none;
        border-radius: 12px;
        display: flex;
        align-items: center;
        transition: .25s ease-in-out all;
        font-weight: 600;
        @media screen and (max-width: $md) {
            padding: 1rem 2rem;
            text-align: left;
            white-space: nowrap;
            font-size: 0.875rem;
        }
        &::before {
            content: "";
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 10px;
            background: white;
            background: url("/static/images/icons/arrow-circle.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }
        &:hover, &:focus {
            color: $white;
            background: $gradientButtonDarker;
        }
    }

    &-netflix {
        background: linear-gradient(132deg, #B00000 0%, #D90000 14.29%, #F11414 28.57%, #F91055 42.86%, #FF007A 58.33%, #EC008E 71.42%, #F700A3 85.71%, #FA00FF 100%);         color: $white;
        padding: 1rem 3rem;
        text-decoration: none;
        border-radius: 12px;
        display: flex;
        align-items: center;
        transition: .25s ease-in-out all;
        font-weight: 600;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        &::before {
            content: "";
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 10px;
            background: white;
            background: url("/static/images/icons/arrow-circle.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }
        &:hover {
            transform: scale(1.05);
            color: $white;

        }
    }


    &-administration {
        background: none;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0.75rem 2.35rem;
        color: #FFF;
        background-clip: padding-box;
        text-decoration: none;
        text-transform: uppercase;
        border-radius: 8px;
        white-space: nowrap;
        border: 2px solid $blue;

        @media screen and (max-width: $sm) {
            padding: 0.5rem 1.5rem;
            max-width: unset;
            white-space: nowrap;
            background: none;
            font-size: 0.75rem;
            border: 2px solid $blue;
        }

        &:hover {
            background: $gradientDefault;
            color: $white;
            border-color: $blue;

        }

    }
    &-bordered {
        color: $white;
        text-decoration: none;
        font-size: .75rem;
        border: 3px solid $borderPrimary;
        display: inline-block;
        padding: .5rem 1.5rem;
        border-radius: 8px;
        background: unset;
        white-space: nowrap;
        background: $bgDefault;
        font-weight: 500;

        @media screen and (max-width: $sm) {
            padding: 0.75rem 1.5rem;
        }
        @media screen and (max-width: $xxl) {
            font-size: 0.75rem;
        }
        &:hover {
            color: $white;
            background-color: $borderPrimary;
        }
    }
    &.small {
        padding: 0.75rem 1rem;
        font-size: 13px;
        border-radius: 12px;
    }
}
