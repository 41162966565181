
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    
@import "@/styles/_variables.scss";

.benefits {
    margin: 1rem 0;
    background: $bgDefault;
    box-shadow: 0px 0px 70px 0px #000;
    &::after, &::before {
        content: "";
        height: 2px;
        display: block;
        width: 100%;
        background: radial-gradient(circle, rgba(0,228,180,1) 0%, rgba(0,210,191,1) 8%, rgba(0,174,213,1) 15%, rgba(0,156,225,1) 31%, rgba(0,139,236,0.8) 49%, rgba(0,121,247,0.3) 84%, rgba(0,108,255,0.1) 93%);
    }

    &-wrap {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        max-width: 1100px;
        margin: auto;
        @media screen and (max-width: $lg) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: $sm) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &-item {
        display: flex;
        align-items: center;
        padding: 1.5rem 1rem;
        @media screen and (max-width: $lg) {
            justify-content: center;
        }

        &-icon {

        }
        &-text {
            text-transform: uppercase;
            font-size: 0.875rem;
            color: $white;
            margin-left: 1rem;
        }
    }
}
