
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    
@import "@/styles/_variables.scss";

.price-list {
    padding: 4rem 0;
    padding-top: 1rem;
    @media screen and (max-width: $lg) {
        padding: 2rem 0;
    }
    &-cards {
        margin-top: 5rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;

        @media screen and (max-width: $lg) {
            grid-template-columns: 1fr;
        }
    }
}
.price-carousel {
    padding: 1rem;
    margin-bottom: 2rem;
    .slider-wrapper  {
        padding: 8rem 0;
        .slide  {
           padding: 0 1rem;
        }
    }
    &-slider {
        text-align: center;
        transform: translateY(-50px);

    }
    &-buttons {
        margin-top: 1rem;
        display: flex;
        gap: 0.5rem;
        button {
            width: 33%;
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }
}
