
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    
@import "@/styles/_variables.scss";

.guarantee {
    background: $bgDefault;
    box-shadow: 0px 0px 70px 0px #000;

    &::after, &::before {
        content: "";
        height: 2px;
        display: block;
        width: 100%;
        background: radial-gradient(circle, rgba(0,228,180,1) 0%, rgba(0,210,191,1) 8%, rgba(0,174,213,1) 15%, rgba(0,156,225,1) 31%, rgba(0,139,236,0.8) 49%, rgba(0,121,247,0.3) 84%, rgba(0,108,255,0.1) 93%);
    }
    &-content {
        padding-top: 3rem;
        padding-bottom: 1rem;
    }
    &-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        @media screen and (max-width: $sm) {
            flex-direction: column;
        }
    }
}
