
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    
@import "@/styles/_variables.scss";

.access {
  @media screen and (min-width: $md) {
    overflow-x: hidden;
  }
  padding: 4rem 0;
  @media screen and (max-width: $lg) {
    padding: 2rem 0;
  }
  @media screen and (max-width: $md) {
    padding-bottom: 0;
  }
  &-content {
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    margin: auto;
    margin-top: 4rem;
    @media screen and (max-width: $md) {
      margin-top: 8rem;
    }
  }
  &-item {
    min-height: 300px;
    position: relative;
    $border: 5px;
    color: #fff;
    background: $black;
    background-clip: padding-box;
    border-radius: 1em;
    display: flex;
    box-shadow: 0 0 120px 0 rgba(#00c5f0, 0.2),
      20px 20px 70px 0 rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(7.5px);
    margin: 2px;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem 3.5rem;
    @media screen and (max-width: $md) {
      padding-top: 160px;
    }
    @media screen and (max-width: $sm) {
      padding: 2rem;
      padding-top: 130px;
    }
    &-box {
      background: linear-gradient(
          276.05deg,
          #00e4b4 -47.05%,
          #12ecc4 -21.01%,
          #00c0ca 5.03%,
          #00aed5 31.06%,
          #009ce1 57.1%,
          #008bec 83.14%,
          #0079f7 109.18%,
          #085CFF 135.22%
        ),
        linear-gradient(
          270.5deg,
          rgba(0, 228, 180, 0.15) 2.11%,
          rgba(0, 108, 255, 0.15) 99.41%
        );
      border-radius: 1em;
      margin-bottom: 2rem;
      @media screen and (max-width: $md) {
        margin-bottom: 8rem;
        &:last-child {
          margin-bottom: 2rem;
        }
      }
    }
    &-text {
      width: 100%;
      max-width: 70%;
      @media screen and (max-width: $md) {
        max-width: 100%;
        text-align: left;
      }
      h3 {
        color: $white;
        font-size: 2rem;
        margin-bottom: 1rem;
        @media screen and (max-width: $md) {
          font-size: 1.25rem;
          text-align: left;
          display: flex;
          gap: 0.33rem;
        }
        svg {
          display: inline-block;
          margin-left: 0.5rem;
          margin-top: -0.35rem;
          @media screen and (max-width: $md) {
            margin-top: -0.2rem;
            margin-left: 0;
          }
        }
      }
      p {
        font-size: 1.125rem;
        color: $grayDark;
        max-width: 600px;
        margin-bottom: 0;
      }
    }
    &-image {
      min-height: 132px;
      position: absolute;
      max-width: 300px;
      right: 0;
      top: 50%;
      transform: translateY(-50%) translateX(70px);
      @media screen and (max-width: $md) {

        position: absolute;
        top: unset;
        right: unset;
        bottom: 70%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 300px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
      }
      @media screen and (max-width: $sm) {
        bottom: 80%;
        vertical-align: bottom;
        align-items: flex-end;
      }
      img {
        width: unset;
        height: unset;
        object-fit: cover;
        @media screen and (max-width: $md) {
          height: unset;
          width: unset;
          max-height: 200px;
        }

        @media screen and (max-width: $sm) {
          height: unset;
          width: unset;
          max-height: 150px;
        }
      }
    }
    &-gap {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;
      &-title {
        text-align: center;
        color: $white;
        text-transform: uppercase;
        font-size: 19px;
        margin-bottom: 0;
        font-weight: 700;
        margin-top: 0.5rem;
        span {
          letter-spacing: 0em;
          text-align: center;
          font-weight: 800;
          font-size: 20px;
          background: linear-gradient(93.2deg, #00e4b4 8.08%, #085CFF 120.97%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        &-large {
          font-size: 25px !important;
        }
      }
      @media screen and (max-width: $md) {
        margin-top: -5.5rem;
        margin-bottom: 8.75rem;
      }
    }
    &-priceBefore {
      font-size: 1rem;
      font-weight: 700;
      color: #c6c6c6;
      position: relative;
      width: fit-content;
      width: -moz-fit-content;
      &::before {
        content: "";
        position: absolute;
        top: 45%;
        left: 0;
        width: 94%;
        height: 2px;
        background: #b9b9b9;
        transform: rotate(25deg);
        box-shadow: 0px 0px 4px 0px #000000;
      }
    }
    &-specialOffer {
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.75rem;
      margin-top: 1rem;
    }
    &-highlightedText {
      background: linear-gradient(93.2deg, #00e4b4 8.08%, #085CFF 120.97%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 1rem;
      font-weight: 800;
      text-transform: uppercase;
    }
    &-background {
      background: linear-gradient(
          276.05deg,
          #00e4b4 -47.05%,
          #12ecc4 -21.01%,
          #00c0ca 5.03%,
          #00aed5 31.06%,
          #009ce1 57.1%,
          #008bec 83.14%,
          #0079f7 109.18%,
          #085CFF 135.22%
        ),
        linear-gradient(
          270.5deg,
          rgba(0, 228, 180, 0.15) 2.11%,
          rgba(0, 108, 255, 0.15) 99.41%
        );
      position: relative;
      & > * {
        z-index: 3;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: red;
        z-index: 2;
        background: rgb(0 0 0 / 80%);
        border-radius: calc(1em - 1px);
      }
    }
  }
}
