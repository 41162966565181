
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    

.payment-options {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    justify-content: center;
    @media screen and (max-width: $md) {
        margin-top: 0;
    }
    &-item {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        > span {
            color: $white;
            font-size: 10px;
            display: block;
            margin-bottom: 0.25rem;
        }
    }
    &-image {

    }
}
