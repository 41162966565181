
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    
button {
  font-family: inherit;
}

.price-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  box-shadow: 20px 20px 70px 0px rgba(0, 0, 0, 0.5);
  background: #0d12161a;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.5s ease-in-out all;
  max-width: 360px;
  background: $gradientDefault;
  &.inactive {
    background: $borderPrimary;
  }

  &-inner {
    border-radius: 0.75rem;
    overflow: hidden;
    padding: 1px;
    width: 100%;

    &-small {
      background: $black;
      border-radius: 0.75rem;
      padding: 2rem;
    }
  }

  margin: auto;
  &.active,
  &:hover:not(.no-hover) {
    //border: 3px solid $blue;
    transform: scale(1.1);
    .price-card-inner {
      padding: 3px;
    }
    &::after {
      content: "";
      background: linear-gradient(
        334deg,
        rgba(0, 228, 180, 0.2) 0%,
        rgba(0, 210, 191, 0.2) 14.29%,
        rgba(0, 192, 202, 0.2) 28.57%,
        rgba(0, 174, 213, 0.2) 42.86%,
        rgba(0, 156, 225, 0.2) 57.14%,
        rgba(0, 139, 236, 0.2) 71.43%,
        rgba(0, 121, 247, 0.2) 85.71%,
        rgba(0, 108, 255, 0.2) 100%
      );
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      filter: blur(200px);
      z-index: 1;
      pointer-events: none;
      @media screen and (max-width: $lg) {
        filter: blur(50px);
      }
    }
    button,
    a {
      background: $gradientButton;
    }
  }
  &-title {
    color: $white;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
    @media screen and (max-width: $xl) {
      font-size: 0.875rem;
    }
  }
  &-small {
    color: $teal;
    font-size: 0.75rem;
    margin-bottom: 1.25rem;
    @media screen and (max-width: $xl) {
      font-size: 0.7rem;
    }
  }
  &-value {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span:first-child {
      font-size: 1.875rem;
      font-weight: 600;
      color: $white;
      position: relative;
      z-index: 1;
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(
            276.05deg,
            #00e4b4 -47.05%,
            #12ecc4 -21.01%,
            #00c0ca 5.03%,
            #00aed5 31.06%,
            #009ce1 57.1%,
            #008bec 83.14%,
            #0079f7 109.18%,
            #085CFF 135.22%
          ),
          linear-gradient(
            270.5deg,
            rgba(0, 228, 180, 0.15) 2.11%,
            rgba(0, 108, 255, 0.15) 99.41%
          );
        opacity: 0.5;
        filter: blur(10px);
        display: block;
        width: 100%;
        height: 26%;
        z-index: -1;
      }
    }
    span:last-child {
      color: $teal;
      font-size: 0.75rem;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    margin-top: 1.25rem;
    li {
      font-size: 0.875rem;
      color: $white;
      font-weight: 600;
      display: flex;
      margin-bottom: 1rem;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: $xl) {
        font-size: 0.75rem;
      }
      span {
        display: block;
      }
      &.icon-complete::before {
        content: "";
        display: flex;
        width: 12px;
        height: 12px;
        background-image: url("/static/images/icons/complete.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-right: 0.5rem;
      }
      &.icon-lock::before {
        content: "";
        display: flex;
        width: 12px;
        height: 12px;
        background-image: url("/static/images/icons/lock.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-right: 0.5rem;
      }
      &.icon-present::before {
        content: "";
        display: flex;
        width: 24px;
        height: 24px;
        background-image: url("/static/images/icons/present.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-right: 0.5rem;
      }
    }
  }
  button,
  a {
    box-shadow: 0px 0px 10px 0px #00000040;
    border: 1px solid $blue;
    color: $white;
    text-align: center;
    max-width: fit-content;
    //display: block;
    padding: 1rem 3rem;
    text-decoration: none;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.25s ease-in-out all;
    //font-weight: 600;
    margin: auto;
    font-size: 0.75rem;
    margin-top: 1rem;
    //white-space: nowrap;
    cursor: pointer;
    text-transform: capitalize;
    background: none;
    font-weight: 700;
    @media screen and (max-width: $xl) {
      padding: 0.75rem 1.5rem;
      font-size: 0.7rem;
      white-space: inherit;
    }
    @media screen and (max-width: $lg) {
      padding: 0.75rem 1.5rem;
      font-size: 0.7rem;
    }
    &::before {
      content: "";
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 10px;
      background: white;
      background: url("/static/images/icons/arrow-circle.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:hover {
      background: $gradientButtonDarker!important;
    }
  }
  &.administration {
    button {
      text-transform: uppercase;
      &:before {
        display: none;
      }
    }
  }
  &.administration.active {
    ul {
      li {
        &:before {
          background: url("/static/images/icons/check-gradient.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
      }
    }
  }
}
