
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    
@import "@/styles/_variables.scss";

.faq {
    margin-top: 3rem;
    @media screen and (max-width: $lg) {
        padding: 2rem 0;
    }
    &-content {
        display: flex;
        flex-direction: column;
        max-width: 800px;
        margin: auto;
        padding: 1rem;
    }
    &-item {
        margin-bottom: 1rem;
        border-radius: 0.75rem;
        overflow: hidden;
        background: $gradientDefault;
        display: flex;
        &-inner {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            background: $bgDefault;
            margin: 2px;
            padding: 1rem 1.25rem;
            border-radius: 0.75rem;
        }

        &-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background: none;
            border: none;
            cursor: pointer;
            position: relative;

            > span {
                color: $white;
                font-size: 1.25rem;
                font-weight: 600;
                @media screen and (max-width: $md) {
                    font-size: 0.875rem;
                    padding-right: 1rem;
                }
            }
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                border-radius: 50px;
                border: none;
                cursor: pointer;
                background: url("/static/images/addons/union-gradient.png");
                background-size: contain;
                background-repeat: no-repeat;
                position: relative;
                width: 100%;
                max-width: 30px;


                span:first-child {
                    content: "";
                    width: 1px;
                    height: 12px;
                    background: $gradientButton;
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                span:last-child {
                    width: 12px;
                    height: 1px;
                    background: $gradientButton;
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                &.active, &:hover {
                    background: $gradientButton;
                    span:last-child {

                        background: $bgDefault;
                    }
                    span:first-child {
                        display: none;
                    }
                }
            }
        }
        &-description {
            font-size: 1rem;
            color: $grayDark;
            opacity: 0;
            height: 100%;
            max-height: 0;
            transition: .5s ease max-height;
            transition: .5s ease padding;
            padding-right: 3rem;

            &.active {
                opacity: 1;
                max-height: 200px;
                padding-top: 1rem;

            }
        }
    }
}
