
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    


.hero {
    padding: 0;
    margin: 0;
    padding-top: 6rem;
    padding-bottom: 4rem;
    position: relative;
    &-content {
        max-width: 800px;
        margin: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        z-index: 2;
        position: relative;
        align-items: center;
        justify-content: center;
        h1 {
            font-size: 2.25rem;
            margin: 0;
            margin-bottom: .5rem;
            color: $white;
            @media screen and (max-width: $lg) {
                margin-top: 2rem;
            }
            @media screen and (max-width: $md) {
                font-size: 1.5rem;
            }
            span {
                background: $gradientDefault;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
            }
        }
        p {
            font-size: 1.25rem;
            color: $gray;
            max-width: 500px;
            font-weight: 300;
            margin: 0;
        }
        &-video {
            position: relative;
            width: 100%;
            padding-top: 53.25%;
            margin: 1rem 0;
            > div {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 99;
                width: 100%!important;
                height: 100%;
                .react-player__preview {
                    border: 3px solid $borderVideo;
                    border-radius: 12px;
                    overflow: hidden;

                }
                /*&:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 35px;
                    background-image: url("/static/images/video-control.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    z-index: 99;
                }*/
            }
            &-video.playing {
                > div:after {
                    display: none;
                }
            }

            &:after {
                content: "";
                background: $blueShadow;
                border-radius: 50px;
                filter: blur(80px);
                width: 80%;
                height: 300px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                background: $gradientDefault;

                @media screen and (max-width: $lg) {
                    height: 150px;
                    filter: blur(80px);
                }
            }
            /*&:before {
                content: "";
                background: $greenShadow;
                border-radius: 50px;
                width: 350px;
                height: 350px;
                filter: blur(125px);
                position: absolute;
                right: 5%;
                top: 50%;
                transform: translateY(-50%);
                z-index: 2;
                @media screen and (max-width: $lg) {
                    width: 150px;
                    height: 150px;
                    filter: blur(100px);
                }
            }*/
            video {
                width: 100%;
                height: 100%;
                z-index: 3;
                border: 3px solid #36323F;
                border-radius: 16px;
            }
        }
        &-cta {
            margin-top: 1rem;
        }
    }

}
