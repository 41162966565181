
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    

.members {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    &-wrap {
        display: flex;
        flex-direction: row;
    }
    &-item {
        border: 2px solid $black;
        width: 22px;
        height: 22px;
        display: flex;
        border-radius: 50px;
        overflow: hidden;
        margin-left: -5px;
        &:first-child {
            margin: 0;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    p {
        margin-left: 0.675rem;
        background: linear-gradient(183deg, #FFF 0%, rgba(246, 246, 246, 0.00) 100%, rgba(223, 223, 223, 0.50) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1rem;
        font-weight: 500;
        span {
            background: linear-gradient(179deg, #FFF 0%, rgba(246, 246, 246, 0.00) 100%, rgba(223, 223, 223, 0.50) 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

        }
    }
}
