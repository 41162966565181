
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    
@import "@/styles/_variables.scss";

.points {
    padding: 3rem 0;
    margin: 0;
    position: relative;
    overflow: visible;
    @media screen and (max-width: $lg) {
        padding: 2rem 0;
    }
    &-content {
        position: relative;
    }
    &-line {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 60px;
        top: 50%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: $md) {
            left: 0;
            transform: translateY(-50%);
        }
        &::after {
            content: '';
            width: 1px;
            height: 100%;
            background: $white;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
        }
        &-item {
            width: 100%;
            max-width: 60px;
            height: 60px;
            border-radius: 16px;
            font-size: 24px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $black;
            border: 1px solid $white;
            box-shadow: 0 0 40px 0 rgba(0, 197, 240, 0.2);
            background: $white;
            z-index: 5;
            position: relative;

        }
    }
    &-title {
        z-index: 6;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h2 {
            margin: 0;
            font-size: 1.875rem;
            color: $white;
            font-weight: bold;
        }
        > span {
            background: $gradientDefault;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-transform: uppercase;
            margin-top: 1rem;
        }
    }

    &-content {
        z-index: 6;
        position: relative;
        margin-top: 4rem;
        > div {
            &:first-child {
                .points-item-photo {
                    img {
                        min-height: 270px;
                    }
                    &-content {
                        border: none;
                        background: none;

                    }
                    &:after {
                        content: "";
                        background: $blueShadow;
                        border-radius: 50px;
                        filter: blur(50px);
                        width: 100px;
                        height: 100px;
                        position: absolute;
                        bottom: 25%;
                        left: 30%;
                        z-index: 2;
                        @media screen and (min-width: $xl) {
                            bottom: 25%;
                            left: 30%;
                        }
                    }
                    &:before {
                        content: "";
                        background: $greenShadow;
                        border-radius: 50px;
                        width: 100px;
                        height: 100px;
                        filter: blur(50px);
                        position: absolute;
                        right: 25%;
                        top: -5%;
                        z-index: 2;
                        @media screen and (min-width: $xl) {
                            right: 25%;
                            top: -5%;
                        }
                    }
                }
            }
        }
    }
    &-item {

        &-photo-content {
            border-radius: 12px;
            overflow: hidden;
            z-index: 7;
            position: relative;
            padding: 2px;
            background: $borderVideo;
        }

        &-photo {
            position: relative;
            z-index: 6;
            display: flex;
            justify-content: center;
            align-items: center;
            &:after {
                content: "";
                background: $blueShadow;
                border-radius: 50px;
                filter: blur(40px);
                width: 150px;
                height: 100px;
                position: absolute;
                left: 5%;
                bottom: 50%;
                transform: translateY(50%);
                z-index: 2;

            }
            &:before {
                content: "";
                background: $greenShadow;
                border-radius: 50px;
                width: 150px;
                height: 100px;
                filter: blur(40px);
                position: absolute;
                right: 5%;
                bottom: 50%;
                transform: translateY(50%);
                z-index: 2;

            }
            img {
                width: auto;
                border-radius: 12px;
            }
        }
        @media screen and (min-width: $xl) {
            & > div:nth-child(odd) {
                margin-left: 6.25rem;
                &.points-item-text {
                    align-items: flex-start;
                }
                .points-item-photo-content {
                    margin: auto;
                }
            }
            & > div:nth-child(even) {
                margin-right: 6.25rem;
                &.points-item-text {
                    align-items: flex-start;
                }
                .points-item-photo-content {
                    margin: auto;
                }
            }
            &-photo {
                padding: 1.5rem 0;
                display: flex;
                &:after {
                    content: "";
                    background: $blueShadow;
                    border-radius: 50px;
                    filter: blur(70px);
                    width: 200px;
                    height: 200px;
                    position: absolute;
                    left: 5%;
                    bottom: 50%;
                    transform: translateY(50%);
                    z-index: 2;

                }
                &:before {
                    content: "";
                    background: $greenShadow;
                    border-radius: 50px;
                    width: 200px;
                    height: 200px;
                    filter: blur(50px);
                    position: absolute;
                    right: 5%;
                    bottom: 50%;
                    transform: translateY(50%);
                    z-index: 2;

                }
                img {
                    max-height: 300px;
                    object-fit: scale-down;
                    z-index: 6;
                    position: relative;
                }
            }
        }

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10rem;
        margin-bottom: 1rem;
        @media screen and (max-width: $xl) {
            margin-bottom: 4rem;
        }
        @media screen and (max-width: $lg) {
            margin-bottom: 4rem;
        }
        @media screen and (max-width: $md) {
            grid-template-columns: 1fr;
            padding-left: 4rem;
            margin-bottom: 2rem;
        }
        &:nth-child(3) {
            margin: 0;
        }
        &-text {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @media screen and (max-width: $md) {
                justify-content: flex-start;
                align-items: flex-start;
                padding: 2rem;
            }
            h3 {
                margin: 0 0 1rem;
                font-size: 2rem;
                color: $white;
                font-weight: 700;
                max-width: 450px;
                @media screen and (max-width: $lg) {
                    font-size: 1.5rem;
                }
                @media screen and (max-width: $md) {
                    max-width: unset;
                }
                @media screen and (max-width: $sm) {
                    font-size: 1rem;
                }
                > span {
                    background: $gradientDefault;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            p {
                color: $teal;
                font-size: 1.125rem;
                max-width: 450px;
                @media screen and (max-width: $lg) {
                    font-size: .875rem;
                }
                @media screen and (max-width: $sm) {
                    font-size: 0.875rem;
                }
                @media screen and (max-width: $md) {
                    max-width: unset;
                }
            }
        }
        &-photo {
            text-align: center;
            @media screen and (max-width: $md) {
                display: none;
            }
            img {
                text-align: center;
            }
        }
    }
}
