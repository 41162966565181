
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    

.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
    > span {
        background: $gradientDefault;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;
        font-weight: 600;
    }
    h2 {
        color: $white;
        font-size: 2.5rem;
        font-weight: 700;
        margin: 0.5rem;
        margin-bottom: 1rem;
        text-transform: capitalize;
        @media screen and (max-width: $lg) {
            font-size: 1.5rem;
        }
        > span {
            background: $gradientDefault;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 600;
        }
    }
    p {
        max-width: 600px;
        margin: auto;
        display: block;
        color: $gray;
        font-size: 1.125rem;
        @media screen and (max-width: $lg) {
            font-size: 0.875rem;
            max-width: 420px;
        }
        &.mw-full {
            max-width: 700px;
        }
    }
    &-bottom {
        display: block;
        text-transform: uppercase;
    }
}
