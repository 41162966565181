
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    

.footer {
    padding: 2rem 0;
    &-content {
        flex-direction: column;
        display: flex;
        &::before {
            content: "";
            height: 2px;
            display: block;
            width: 100%;
            background: radial-gradient(circle, rgba(0,228,180,1) 0%, rgba(0,210,191,1) 8%, rgba(0,174,213,1) 15%, rgba(0,156,225,1) 31%, rgba(0,139,236,0.8) 49%, rgba(0,121,247,0.3) 84%, rgba(0,108,255,0.1) 93%);
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            text-align: center;
            margin-top: 0.5rem;
            li {
                a {
                    text-decoration: none;
                    color: $white;
                    font-size: 0.75rem;
                    font-weight: 300;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1.5rem;
        flex-direction: column;
    }
}
