
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    
.header {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 1rem;
    background: none;
    z-index: 999;
    &-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-left {

        }
        &-right {
            display: flex;
            align-items:center;
            gap: 1rem;
        }
        &-logo {
            display: flex;
            align-items: center;
        }
        img {
            @media screen and (max-width: $sm) {
                max-width: 140px;
            }
        }
    }
}
