
            @import "@/styles/_variables.scss";
            @import "@/styles/_mixins.scss";
    
.language-picker {
    position: relative;
  @media screen and (max-width: 400px) {
      display: none;
  }
    > button {
      z-index: 3;
      padding: .25rem 1.25rem;
      background: none;
      border-radius: 50px;
      background: $bgDefault;
      border: 3px solid $borderPrimary;
      position: relative;
      span {
        color: $white;
      }
    }
    &-dropdown {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: $bgDefault;
      border-radius: 16px;
      z-index: 0;
      padding: 35px 5px 10px;
      border: 2px solid $borderPrimary;
      transition: 0.5s ease-in-out all;
      display: none;
      ul {
        padding: 0;
        list-style: none;
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        li {
          text-align: center;
          width: 100%;
           > button, > a {
             background: none;
             border: none;
            width: 100%;
            border-radius: 50px;
            text-align: center;
            color: $white;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              background: $gradientDefault;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }
    &:hover {
      .language-picker-dropdown {
        display: block;
      }
    }
  }
